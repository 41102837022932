import React from 'react';
import { Box, ResponsiveContext, Heading } from 'grommet';
import PricingCardFransuite from './PricingCardFransuite';
import CtaBannerAlt from '../CtaBannerAlt';

const pad = {
  small: 'large',
  hero: 'medium',
};

export default function({ Link, headline, tiers, buttonHeadline, buttonText, buttonUrl }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = size === 'small';
        const hero = size === 'hero';

        return (
          <Box
            id="pricing"
            pad={{ vertical: 'xlarge', horizontal: small || hero ? pad[size] : 'xlarge' }}
            background="dark-2"
            align="center"
          >
            {headline && (
              <Heading level="2" textAlign="center" margin={{ bottom: 'xlarge' }}>
                {headline}
              </Heading>
            )}
            <Box
              direction="row-responsive"
              gap={small ? 'xlarge' : '30px'}
              alignSelf="stretch"
              alignContent="center"
              margin={{ bottom: 'xlarge' }}
            >
              {tiers.map((tier, index) => (
                <PricingCardFransuite key={tier.id} Link={Link} {...tier} first={index === 0} />
              ))}
            </Box>
            {buttonHeadline && buttonUrl && buttonText && (
              <CtaBannerAlt
                Link={Link}
                headline={buttonHeadline}
                buttonText={buttonText}
                buttonUrl={buttonUrl}
              />
            )}
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
