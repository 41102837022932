import React from 'react';
import Hero from '../components/HeroInterior';
import CtaSection from '../components/CtaSection';
import FeatureList from '../components/FeatureList';
import HighlightList from '../components/HighlightList';
import LogoGridCustomer from '../components/LogoGridCustomer';
import PricingFransuite from '../components/PricingFransuite';

export default function({ doc, Link }) {
  if (!doc) return null;

  const { hero, highlights, pricing, logos, features, cta } = doc;

  return (
    <>
      <Hero Link={Link} {...hero} />
      <HighlightList Link={Link} {...highlights} />
      <PricingFransuite Link={Link} {...pricing} />
      <LogoGridCustomer {...logos} />
      <FeatureList {...features} />
      <CtaSection Link={Link} {...cta} />
    </>
  );
}
