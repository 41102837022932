import React from 'react';
import { Box, Heading, ResponsiveContext } from 'grommet';
import FeatureCard from './FeatureCard';

export default function({ Link, headline, cards }) {
  return (
    <ResponsiveContext.Consumer>
      {(size) => {
        const small = size === 'small';
        return (
          <Box background="white" pad={small ? 'large' : 'xlarge'}>
            {headline && (
              <Heading
                margin={{ bottom: 'xlarge' }}
                level="2"
                alignSelf="center"
                textAlign="center"
              >
                {headline}
              </Heading>
            )}
            <Box
              justify="between"
              direction="row-responsive"
              gap={size === 'small' ? 'xlarge' : 'medium'}
            >
              {cards &&
                cards.map((card) => (
                  <FeatureCard basis={`1/${cards.length}`} Link={Link} {...card} />
                ))}
            </Box>
          </Box>
        );
      }}
    </ResponsiveContext.Consumer>
  );
}
